import thegraph from '../assets/environments/The-Graph.svg'
import oneinch from '../assets/environments/1Inch.svg'
import balancer from '../assets/environments/Balancer.svg'
import paraswap from '../assets/environments/paraswap.svg'
import beet from '../assets/environments/beet.svg'
import zerion from '../assets/environments/zerion.svg'
import zapper from '../assets/environments/zapper.svg'
import exodus from '../assets/environments/Exodus.svg'
import rainbow from '../assets/environments/Rainbow.svg'
import trezor from '../assets/environments/trezor.svg'
import ledger from '../assets/environments/ledger.svg'
import consensys from '../assets/environments/Consensys.svg'
import mana from '../assets/environments/Mana.svg'
import trust from '../assets/environments/Trust.svg'
import tangem from '../assets/environments/Tangem.svg'
import wConnect from '../assets/environments/WalletConnect.svg'
import safe from '../assets/environments/safe.svg'
import mimic from '../assets/environments/mimic.svg'
import blink from '../assets/environments/blink.svg'

export const EnvironmentsDictionary = {
  '0xe926ce04c4dcdaff621c6e5636a0b3562abb82828f4d4fe12b7ac947a973f0dc': {
    logo: safe,
  },
  '0x45f0177ffed4c491d36d32315e76b56d55fb079187390474af36919b5e88db5e': {
    logo: mimic,
  },
  '0x11a6d2d95b6fbda56128b0e04b7a88ed638e75fa6798512526f398064909a3d4': {
    blink: blink,
  },
  '0xa5c7c8cea0249baff045be0488827fa6a9df5774980a91dc62700c4417952c53': {
    logo: zerion,
  },
  '0x93e4eb250117a846013a0724340b72f4313d5208742dd7ac55599caacb9421b4': {
    name: 'The Graph Refunder',
    logo: thegraph,
  },
  '0xfdd726b115adcf00c983b7a7eda7b64d763d0941d8d1e1bfe5160db2352bf9c7': {
    name: '1inch Tw Airdropper',
    logo: oneinch,
  },
  '0xc246e7c14e90a6014dce3ae173910667031fc2789519f65dcc083f2b78302472': {
    logo: consensys,
  },
  '0x7ec0b23feb8cdf2076cff09548ea89e03df00781726f9f5e1c186ff59d87a727': {
    logo: oneinch,
  },
  '0xc92d45af55db5fbdac57de6803c95d02ac4440087bbfee4c884fa5af09ec517d': {
    logo: wConnect,
  },
  '0x082bb58c4cab9dc69f3d647564e30d741b2293fad75e8d9a15a4dbe083616b4b': {
    logo: ledger,
  },
  '0xc052a97cc536e38c7b4d03708c730454691a64cd277aa05e76a6db6bb880d3d0': {
    logo: ledger,
  },
  '0x2abe5da9b0e8daa0e0cdd50ceb0b6aa6f761d7717796451b0d913da62fadff1d' : {
    logo: tangem,
  },
  '0x98df30c628e4fea19b7cf2f3e9b64969054fc92d4d355fcaf8c2ed8d7265afcc': {
    logo: trust,
  },
  '0xbbe341319bd26ad0c17f80b3c1cbb82220fd1294f57d007db5885fde1eac78c1': {
    logo: trust,
  },
  '0x840db882bf072e8a0d7444ff97f2bd24f60505105bc7b4dcd82fa00d1dcb44f6': {
    logo: trust,
  },
  '0x6bd534e21e58fb491a7aed737d483b94b4238b2a7527a029dcd52fe93678484b': {
    logo: mana,
  },
  '0xcb62e34ae5c64e59518dd829381bf6d2afb5d52e4ca9c50c5b0d1cd30c98ea9f' : {
    logo: oneinch,
  },
  '0xd28bd4e036df02abce84bc34ede2a63abcefa0567ff2d923f01c24633262c7f8' : {
    logo: balancer,
  },
  '0x9f2ad2c9daab651cb3e704ff3cbbfd31629331b8cf06385e1f0a249e9704d42b' : {
    logo: paraswap,
    name: 'ParaSwap Fee Collector V3'
  },
  '0xd0072def1ccb1482a6d0f573d5df43c973811cbdbb1e352243ebe0be83c76171' : {
    logo: paraswap,
    name: 'ParaSwap Fee Collector'
  },
  '0xcbb8cbc108ced6d2b1038d7694f5279f48b88496a72137fe36523f9d42b42787' : {
    logo: beet,
    defaultChainId: 250
  },
  '0x769d073d4d4ba34283aab39d9c88461f35fddf912d64ad62528efffeb6707c38': {
    logo: exodus,
  },
  '0x9dbf0fa9ad2f59a8355b9e49e8d4dfff229cafe3bfe82ae5e16674c5accbe8dd': {
    logo: exodus,
  },
  '0x97da7fdc3bcec9c666aeb1c6831cc19879fc23d4a6712d8803f6e6f3f03306d6': {
    logo: rainbow,
  },
  '0xf6aae770acb7f27560cc123f2bc86482bfeaf778c401990773b94c2c3e336b87': {
    logo: trezor,
  },
  '0xbf8b679d6674f313a2fb9841a6dbc8c3899fbeec698d8246213e851070b3797a': {
    logo: zapper,
  },
  '0xbb00280c4e98f2dbe857b7bb30283872f5b84260280d267015bb514419fb1ce3': {
    logo: zapper,
  },
  '0xde0b2fae496c74ac9688988ca6c482b26fd2682180248e7237cb63651dca12c5': {
    logo: zerion,
  },
}
